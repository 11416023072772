<template>
    <div>
        
        <header>
            <div class="container">

                <div class="col-1-1">
                    <img src="@/assets/images/logo.png" id="logo" />
                    <h1>PORTAL DA TRANSPARÊNCIA</h1>
                    <p><strong>PREFEITURA MUNICIPAL DE BALNEÁRIO CAMBORIÚ</strong> | DIVISÃO DE COMUNICAÇÃO</p>
                </div>
                
            </div>
            
        </header>


        <section id="miolo">

            <div class="container">

                <div class="conteudo">
                    <img src="@/assets/images/seta.png" id="seta" />
                    
                    <h2>HISTÓRICO DE PRESTAÇÃO DE CONTAS</h2>

                    <table>

                        <tr v-for="(item, index) in pdfs" :key="index">
                            <td><span>{{ item.info1 }}</span></td>
                            <td class="col-2-8">{{item.info2}}</td>
                            <td class="col-2-8">
                                <img src="@/assets/images/btn-download.png" @click="down(item)" />
                            </td>
                        </tr>

                    </table>
                    
                </div>

            </div>
        </section>

        <footer>
            <div class="container">
                <div class="col-2-15">
                    <img src="@/assets/images/logo-rodape.png" />
                </div>
                <div class="col-8-15">
                    <p>
                        <strong>Prefeitura Municipal de Balneário Camboriú</strong><br>
                        Rua Dinamarca, 320 - Nações, Balneário Camboriú/SC<br>
                        CEP: 88338-900<br>
                        (47) 3267.7000<br>
                        CNPJ: 83.102.285/0001-07<br>
                        Balneário Camboriú - Capital Catarinense do Turismo
                    </p>
                </div>

                <div class="col-5-15">
                    <span class="copy">
                        © Copyright 2021 - Todos os direitos reservados.<br>
                        Desenvolvido por <a href="http://www.agenciatempobrasil.com.br/" target="_blank">Tempo Brasil</a>.
                    </span>
                </div>
            </div>
        </footer>
        
    </div>
</template>

<script>

export default {
    data: function () {
        return {
            pdfs: []
        }
    },
    

    mounted: function(){
        
        var url_api = 'https://transparenciapmbc.agenciatempobrasil.com.br/admin/api/pdfs';

        this.axios.get(url_api).then((response) => {
            this.pdfs = response.data;
        })
        
    },

    methods: {
        down(item) {
            document.location.href = item.url_pdf;
        }
    },
}
</script>